import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { enums, encrypt, validation, auth, iIncident, envIncidentAction, iEnvIncident, transAction, swal, util } from "../../Imports";
import { useDispatch, useSelector } from "react-redux";
import Attachment from "../Common/Attachment";
import ActionButton from "../Common/ActionButton";
import AuditLogs from "../Common/AuditLogs";
import IncidentStatusUpdate from "../Common/IncidentStatusUpdate";
import Reporter from "../Common/Reporter";
import GPSLocation from "../Common/LocationDetail/GPSLocation";
import EmirateGovEntity from "../Common/LocationDetail/EmirateGovEntity";
import EnvActionButton from "./Env_ActionButton";
import Detail from "./Detail";
import ServiceDetail from "./ServiceDetail";
import { URLRoutes } from "../../Routes/Routes";

const EnvironmentalIncident = (props) => {
  let { id } = useParams();
  id = parseInt(encrypt.decrypt(id));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = auth.getUserPermission();
  const transaction = useSelector((state) => state.transaction);

  const handleChangeDTO = (dto, state) => {
    dispatch(transAction.setTransactionDto(dto, state));
  };
  const handleChange = (name, value, state) => {
    dispatch(transAction.setTransactionbyName(name, value, state));
  };
  const getCallCenterGovtId = () => {
    return process.env.REACT_APP_ENV === "prod" ? 82 : 78;
  };
  useEffect(() => {
    // console.log(transaction);
  }, [transaction]);

  useEffect(() => {
    dispatch(transAction.newTransaction());
    if (id === 0) handleChange("GovernmetnalEntityId", getCallCenterGovtId(), iIncident.SharedDTO.IT);
    else if (id > 0) {
      getIncidentTransaction(id);
    } else {
      props.setLoading(false);
    }
  }, [id]);

  const getIncidentTransaction = async (id) => {
    props.setLoading(true);
    const response = await envIncidentAction.getTransactionById(id);
    if (response.data) {
      const res = response.data.ResponseData;

      handleChangeDTO(res, iEnvIncident.EnIncident_Constants.EIT);

      await iIncident.getIncidentTransactionData(res, 0, handleChangeDTO, handleChange);
      validation.isShowInfectionForm(transaction, "agriculturePestForm");
      props.setLoading(false);
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    let data = new iEnvIncident.EnvironmentalIncidentTransactionDTO();
    data.Details = transaction.EnvironmentalIncidentTransactionDTO.Details;

    data.IncidentsTransaction = iIncident.setIncidentTransaction(transaction, enums.IncidentTypeEnum.EnvironmentalIncident);

    data.IncidentsTransaction.GovernmetnalEntityId = getCallCenterGovtId(); //call center

    console.log("data from sever: ", data);
    if (!formValidation()) {
      return false;
    }

    await sendRequest(data);
  };

  const sendRequest = async (data) => {
    let res = null;
    props.setLoading(true);
    if (!transaction.isUpdate) res = await envIncidentAction.create(data);
    else {
      res = await envIncidentAction.update(data);
    }
    if (res.data && res.data.IsSuccess) {
      const respData = res.data.ResponseData;
      if (!transaction.isUpdate) {
        util.swalOKCallBack(props.t("success"), props.t("submitSuccessFully") + props.t("yourRefNoIs") + respData.IncidentsTransaction.RefNo, "success", () => {
          window.location.reload(true);
        });
      } else {
        swal(props.t("success"), props.t("submitSuccessFully"), "success");
        navigate(`/manageincident/${enums.SearchCriteriaEnum.MyIncidents}`);
      }
      props.setLoading(false);
      // if (validation.isMinistryUser()) {

      // } else {
      //navigate(`${URLRoutes.getEnvironmentalIncident(encrypt.encrypt(0), encrypt.encrypt(0))}`);
      // }
    }
  };
  const validateDetailForm_public = (e) => {
    if (transaction.IncidentsMainTransactionDTO.Latitude === "") {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }

    if (transaction.FileAttachmentDTO.length === 0) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }
    return true;
  };
  const validateDetailForm_user = (e) => {
    const actionFrom = validation.validateForm("actionbuttonForm");
    const gpsLocationFrom = validation.validateForm("gpsLocationFrom");
    if (!validation.commonValidation(transaction, props)) {
      return false;
    }

    if (!actionFrom || !gpsLocationFrom) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }

    if (transaction.FileAttachmentDTO.length === 0) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }
    return true;
  };

  const formValidation = () => {
    if (user.isLoggedIn && user.OrganizationId === "") {
      return validateDetailForm_public();
    } else if (user.isLoggedIn && user.OrganizationId !== "") {
      return validateDetailForm_user();
    } else {
      return validateDetailForm_public();
    }
  };

  return (
    <div>
      <div className="tab-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="container page-title p-3 pl-4 mb-3">
              <h3>{props.t("reportEnvironmentalIncident")}</h3>
            </div>
            <div className="col-sm-12 ">
              {!validation.isMinistryUser() && (
                <>
                  <div className="tab-item-wrapper">
                    {transaction.isUpdate && <IncidentStatusUpdate {...props} />}

                    <Detail {...props} />
                  </div>
                  <div className="container" style={{ display: "none" }}>
                    <Reporter handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />
                  </div>
                  <div className="tab-item-wrapper">
                    <EnvActionButton handleChange={handleChange} handleChangeDTO={handleChangeDTO} handleSubmitForm={handleSubmitForm} {...props} />
                  </div>
                </>
              )}

              {validation.isMinistryUser() && (
                <div className="tab-item-wrapper">
                  {transaction.isUpdate && <IncidentStatusUpdate {...props} />}

                  <div className="container ">
                    <Reporter handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />

                    <div className="container-bg my-2">
                      <div className="row">
                        <GPSLocation
                          transaction={transaction}
                          handleChangeDTO={handleChangeDTO}
                          handleChange={handleChange}
                          {...props}
                          hideLatLngTextBox={true}
                        />

                        <EmirateGovEntity
                          {...props}
                          unitType={enums.UnitTypeEnum.GovernmentEntity}
                          department={enums.DepartmentEnum.CallCenterDepartment}
                          isDisabled={true}
                          value={transaction[iIncident.SharedDTO.IT].GovernmetnalEntityId}
                          emirateId={transaction[iIncident.SharedDTO.IT].EmirateId}
                          handleOnChange={(e) => handleChange(e.target.name, e.target.value, iIncident.SharedDTO.IT)}
                        />
                      </div>
                      <ServiceDetail {...props} handleChange={handleChange} />
                    </div>

                    <Attachment
                      required={false}
                      handleChangeDTO={handleChangeDTO}
                      code={enums.AttachmentEnum.Other}
                      canUploadFile={validation.isMinistryUser()}
                      {...props}
                    />
                    {user && <AuditLogs isUpdate={transaction.isUpdate} {...props} />}
                    <ActionButton handleChange={handleChange} handleChangeDTO={handleChangeDTO} handleSubmitForm={handleSubmitForm} {...props} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnvironmentalIncident;
