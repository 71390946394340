import axios from "axios";
import { URLs } from "../../Constants/Common";
import MasterDataClass from "../../Interface/Registration";
import { DispatchTypes } from "../DispatchType";
import { enums, util } from "../../Components/Imports";

export const getUserType = () => {
  return async (dispatch, getState) => {
    if (getState().masterData.userTypeData.length > 0) return false;
    const data = [];
    axios.get(URLs.GetNonGovUserTypesLkp).then((res) => {
      if (res.data.IsSuccess) {
        const resData = res.data.ResponseData;
        resData.forEach((e) => {
          if (e.Code !== enums.NonGovUserTypeEnum.NotRegister) data.push(new MasterDataClass(e.Code, e.NameAr, e.NameEn, e.Code));
        });
        dispatch({
          type: DispatchTypes.GET_USERTYPE,
          userTypeData: data,
        });
      }
    });
  };
};
export const getEmirates = () => {
  return async (dispatch, getState) => {
    const data = [];
    if (getState().masterData.emirateData.length > 0) return false;
    axios.get(URLs.EmirateLkp).then((res) => {
      if (res.data.IsSuccess) {
        const resData = res.data.ResponseData;
        resData.forEach((e) => {
          data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
        });
        dispatch({
          type: DispatchTypes.GET_EMIRATES,
          emirateData: data,
        });
      }
    });
  };
};
export const getWorkFlowStatus = () => {
  return async (dispatch, getState) => {
    if (getState().masterData.workFlowStatus.length > 0) return false;
    const data = [];
    const filter = util.getStatusListFilter();
    axios.get(URLs.WorkflowStateLkp).then((res) => {
      if (res.data.IsSuccess) {
        const resData = res.data.ResponseData;
        resData.forEach((e) => {
          if (filter.includes(e.StatusCode)) data.push(new MasterDataClass(e.StatusCode, e.NameAr, e.NameEn, e.Color));
        });
        dispatch({
          type: DispatchTypes.WORKFLOW_STATUS,
          workFlowStatus: data,
        });
      }
    });
  };
};
export const getGovtEntity = async (emirateId, unitType, departmentId) => {
  const data = [];
  const res = await axios.get(URLs.GovtEntity + `?departmentId=${departmentId}&unitType=${unitType}&emirateId=${emirateId}`);
  if (res.data) {
    res.data.ResponseData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getPortList = async (emirateId) => {
  const data = [];
  const res = await axios.get(URLs.GetPortList + `?emirateId=${emirateId}`);
  if (res.data) {
    res.data.ResponseData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};

export const getHoldingOwnershipType = async () => {
  const data = [];
  const res = await axios.get(URLs.GetHoldingOwnerShipTypeLkp);
  if (res.data) {
    res.data.ResponseData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getUserAccountType = () => {
  return async (dispatch, getState) => {
    if (getState().masterData.UserAccountType.length > 0) return false;
    const data = [];
    axios.get(URLs.GetUserAccountTypeLkp).then((res) => {
      if (res.data.IsSuccess) {
        const resData = res.data.ResponseData;
        resData.forEach((e) => {
          if (e.Id < 4) data.push(new MasterDataClass(e.Code, e.NameAr, e.NameEn, e.Id));
        });
        dispatch({
          type: DispatchTypes.USER_ACCOUNT_TYPE,
          UserAccountType: data,
        });
      }
    });
  };
};
export const getControlProcedures = () => {
  return async (dispatch, getState) => {
    if (getState().masterData.controlProceduresData.length > 0) return false;
    const data = [];
    axios.get(URLs.GetControlProcedureLkp).then((res) => {
      if (res.data.IsSuccess) {
        const resData = res.data.ResponseData;
        resData.forEach((e) => {
          data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.IsDepended));
        });
        dispatch({
          type: DispatchTypes.CONTROL_PROCEDURES,
          controlProceduresData: data,
        });
      }
    });
  };
};
export const getAnimalCatagry = () => {
  return async (dispatch, getState) => {
    if (getState().masterData.animalCatagoryData.length > 0) return false;
    const data = [];
    axios.get(URLs.GetAnimalCatagoryLkp).then((res) => {
      if (res.data.IsSuccess) {
        console.info("getAnimalCatagry", res.data);
        const resData = res.data.ResponseData;
        resData.forEach((e) => {
          data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
        });
        dispatch({
          type: DispatchTypes.GET_ANIMAL_CATAGORY,
          animalCatagoryData: data,
        });
      }
    });
  };
};

export const getAnimalType = async (animalCatId) => {
  const data = [];
  await axios.get(URLs.GetAnimalTypeLkp + `?Id=0&animalCatId=${animalCatId}`).then((res) => {
    if (res.data.IsSuccess) {
      // console.log(res.data);;
      const resData = res.data.ResponseData;
      resData.forEach((e) => {
        data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.ScientificName));
      });
    }
  });
  return data;
};
export const getClinicSymptomLkp = () => {
  return async (dispatch, getState) => {
    if (getState().masterData.ClinicSymptomLkp.length > 0) return false;
    const data = [];
    axios.get(URLs.GetClinicSymptomLkp).then((res) => {
      if (res.data.IsSuccess) {
        const resData = res.data.ResponseData;
        resData.forEach((e) => {
          data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.ScientificName));
        });
        dispatch({
          type: DispatchTypes.GET_CLINICTYPE_SYMPTOMS_LKP,
          ClinicSymptomLkp: data,
        });
      }
    });
  };
};
export const getClinicTypeSymptoms = async (animalTypeId) => {
  const data = [];
  await axios.get(URLs.GetClinicTypeSymptoms + `?animalTypeId=${animalTypeId}`).then((res) => {
    if (res.data.IsSuccess) {
      // console.log(res.data);;
      const resData = res.data.ResponseData;
      resData.forEach((e) => {
        data.push(new MasterDataClass(e.ClinicalSymptoms.Id, e.ClinicalSymptoms.NameAr, e.ClinicalSymptoms.NameEn, ""));
      });
    }
  });
  return data;
};

export const getAnimalDiseaseLkp = async (isSharedDisease = false) => {
  const _data = [];
  const { data } = await axios.get(`${URLs.GetAnimalDiseaseLkp}?isSharedDisease=${isSharedDisease}`);
  if (data.IsSuccess) {
    const resData = data.ResponseData;
    resData.forEach((e) => {
      _data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, ""));
    });
  }
  return _data;
};

export const getAnimalTypeDiseases = async (animalTypeId, animalCatagoryId) => {
  const data = [];
  const res = await axios.get(URLs.GetAnimalDisease + `?animalTypeId=${animalTypeId}&animalCatagoryId=${animalCatagoryId}`);
  if (res.data.IsSuccess) {
    // console.log(res.data);;
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.AnimalDisease.Id, e.AnimalDisease.NameAr, e.AnimalDisease.NameEn, e.AnimalDisease.IsSharedDisease));
    });
  }
  return data;
};
export const addAnimalDisease = async (data) => {
  const res = await axios.post(URLs.AddAnimalDisease, data);
  if (res.data.IsSuccess) {
    console.log(res.data);
    return res.data.ResponseData;
  }
};
export const removeAnimalTypeDisease = async (data) => {
  const res = await axios.post(URLs.RemoveAnimalTypeDisease, data);
  if (res.data.IsSuccess) {
    console.log(res.data);
    return res.data.ResponseData;
  }
};
export const getScientificName = (animalTypeId) => {
  return async (dispatch, getState) => {
    if (getState().masterData.scientificName.length > 0) return false;
    let data = {};
    axios.get(URLs.GetAnimalTypeScientificName + `?animalTypeId=${animalTypeId}`).then((res) => {
      if (res.data.IsSuccess) {
        const e = res.data.ResponseData;
        data = new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.ScientificName);
        dispatch({
          type: DispatchTypes.GET_SCIENTIFIC_NAME,
          scientificName: data,
        });
      }
    });
  };
};

export const getVetEstablishmentLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetVetEstablishmentLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    // console.log(res.data);;
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, ""));
    });
  }
  return data;
};
export const getALVIncidentTypeLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetALVIncidentTypeLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    // console.log(res.data);;
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getPlantTypesLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetPlantTypesLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, ""));
    });
  }
  return data;
};
export const getIncidentSourceLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetIncidentSourceLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, ""));
    });
  }
  return data;
};
export const getBlightDescriptionLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetBlightDescriptionLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, ""));
    });
  }
  return data;
};
export const getAllGovEntityLkp = async (emirateId) => {
  const data = [];
  const res = await axios.get(URLs.GetAllGovEntityLkp + `?emirateId=${emirateId}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, ""));
    });
  }
  return data;
};
export const getInfectionTypeLkp = async (code) => {
  const data = [];
  const res = await axios.get(URLs.GetInfectionTypeLkp);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Code, e.NameAr, e.NameEn, e.Code));
    });
  }

  return data;
};
export const getRiskDegreeLkp = async (code) => {
  const data = [];
  const res = await axios.get(URLs.GetRiskDegreeLkp);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Code, e.NameAr, e.NameEn, e.Color));
    });
  }
  return data;
};

export const getCountryLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetCountryLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getEserviceAnimalCatagoryLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetEserviceAnimalCatagoryLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getEserviceAnimalLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetEserviceAnimalLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getEserviceAgricultureLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetEserviceAgricultureLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getTransportMediaLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetTransportMediaLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getProductGroupLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetProductGroupLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getRiskSourceLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetRiskSourceLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getRiskSourceGroupLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetRiskSourceGroupLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getNotificationTypeLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetNotificationTypeLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getActivityTypeLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetActivityTypeLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getFoodActionTakenLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetFoodActionTakenLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getUnitTypeLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetUnitTypeLkp + `?id=${id}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getIncidentTypeLkp = async (deptId, code) => {
  const data = [];
  const res = await axios.get(URLs.GetIncidentTypeLkp + `?deptId=${deptId}&code=${code}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Code, e.NameAr, e.NameEn, e.Id));
    });
  }
  return data;
};
export const getDepartmentLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetDepartmentLkp);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e, i) => {
      if (i < 4) data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getAllDepartmentLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetDepartmentLkp);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e, i) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const getOrganizationLkp = async (deptId, govEntityId, unitType) => {
  const res = await axios.get(URLs.GetOrganizationLkp + `?deptId=${deptId}&govEntityId=${govEntityId}&unitType=${unitType}`);
  if (res.data.IsSuccess) {
    return res.data.ResponseData;
  }
};
export const getDocumentTypeLkp = async (id) => {
  const data = [];
  const res = await axios.get(URLs.GetDocumentTypeLkp);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Id, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};

export const getOrgUnitTypeLkp = async (departmentId) => {
  const data = [];
  const res = await axios.get(URLs.GetOrgUnitTypeLkp + `?departmentId=${departmentId}`);
  if (res.data.IsSuccess) {
    const resData = res.data.ResponseData;
    resData.forEach((e) => {
      data.push(new MasterDataClass(e.Code, e.NameAr, e.NameEn, e.Code));
    });
  }
  return data;
};
export const createUpdate_ContactUs = async (data) => {
  const res = await axios.post(URLs.CreateUpdate_ContactUs, data);
  return res.data.IsSuccess;
};
export const getSystemConfiguration = async (deptId) => {
  const res = await axios.get(`${URLs.GetSystemConfiguration}?deptId=${deptId}`);
  return res.data;
};
export const createSystemConfiguration = async (data) => {
  const res = await axios.post(URLs.CreateSystemConfiguration, data);
  return res.data;
};
