export const URLRoutes = {
  uaepass: "uaepass",
  LoginFromMobileApp: "LoginFromMobileApp",
  MultipleAccounts: "MultipleAccounts",
  login: "login",
  registration: "registration",
  ProfileLinking: "profileLinking",
  LoginLinked: "loginLinked",
  forgetPassword: "forgetpassword",
  changepassword: "changepassword",
  profile: "profile",
  manageAccount: "manageAccount",
  addNewAccount: "addNewAccount/:id",
  notification: "notification",
  privacypolicy: "privacypolicy",
  manageIncident: "manageincident/:type",
  dashboard: "dashboard/:type",
  documentLib: "documentLib/:id/:mode",
  viewDocumentLib: "viewDocumentLib",
  animalSharedDisease: "animalsharedisease/:id/:followup",

  agriculturePestReport: "agriculture-pest-report/:type",
  systemConfiguration: "system-configuration/:type",
  animalHealthReport: "animal-health-report/:type",
  worldHealthOrganizationReport: "worldHealthOrganizationReport",

  foodSafetyReport: "food-safety-report",
  foodSafetyCountByEmirateReport: "food-safety-count-emirate-report",
  incidentDetail: "incidentdetail/:type/:subType",
  OverAllReport: "OverAllReport",
  AnimalIncidentRedirection: "animal-incident-redirection",
  AnimalDiseaseConfig: "animal-disease-config",

  EnvironmentalIncident: "environmental-incident/:id/:followup",
  getEnvironmentalIncident: (id, followup) => {
    return `/environmental-incident/${id}/${followup}`;
  },
  getAnimalSharedDisease: (id, followup) => {
    return `/animalsharedisease/${id}/${followup}`;
  },
  foodSafety: "foodsafety/:id/:followup",
  getFoodSafety: (id) => {
    return `/foodsafety/${id}`;
  },
  foodSafetyFollowUp: "food-safety-followup/:mainId/:id",
  getFoodSafetyFollowUp: (mainId, id) => {
    return `/food-safety-followup/${mainId}/${id}`;
  },
  foodSafetyBorderGate: "foodSafety-border-gate/:id/:followup",
  getFoodSafetyBorderGate: (id, followup) => {
    return `/foodSafety-border-gate/${id}/${followup}`;
  },

  animalHealthIncident: "animalhealthincident/:id/:followup",
  getAnimalHealthIncident: (id, followup) => {
    return `/animalhealthincident/${id}/${followup}`;
  },

  animalHealthIncidentWithSharedDisease: "animalhealthincident/:id/:followup/:shareDiseaseId",
  getAnimalHealthIncidentWithSharedDisease: (id, followup, shareDiseaseId) => {
    return `/animalhealthincident/${id}/${followup}/${shareDiseaseId}`;
  },

  animalLegalViolation: "animallegalviolation/:id/:followup",
  getAnimalLegalViolation: (id, followup) => {
    return `/animallegalviolation/${id}/${followup}`;
  },

  animalBorderGate: "animal-border-gate/:id/:followup",
  getAnimalBorderGate: (id, followup) => {
    return `/animal-border-gate/${id}/${followup}`;
  },

  agriculturePest: "agriculturepest/:id/:followup",
  getAgriculturePest: (id, followup) => {
    return `/agriculturepest/${id}/${followup}`;
  },
  agricultureBorderGate: "agriculture-border-gate/:id/:followup",
  getAgricultureBorderGate: (id, followup) => {
    return `/agriculture-border-gate/${id}/${followup}`;
  },
};
