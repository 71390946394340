import axios from "axios";
import { URLs } from "../../Constants/Common";

export const getWorkFlowActions = async (
  incidentTypeCode,
  statusCode,
  condition
) => {
  return await axios.get(
    URLs.getWorkFlowActions +
      `?incidentTypeCode=${incidentTypeCode}&statusCode=${statusCode}&condition=${condition}`
  );
};
