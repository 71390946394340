import React, { useCallback, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { auth, enums, encrypt, iAnimalHealth, animalHealthAction, iIncident, validation, transAction, swal, toast } from "../../Imports";
import InfectionDefault from "./InfectionDetail/InfectionDefault";
import InfectionASusceptible from "./InfectionDetail/InfectionASusceptible";
import InfectionASuspected from "./InfectionDetail/InfectionASuspected";
import InfectionCAD from "./InfectionDetail/InfectionCAD";
import InvestigationDetail from "./InvestigationDetail";
import Dead from "./InfectionDetail/Dead";
import Discarded from "./InfectionDetail/Discarded";
import Slaughtered from "./InfectionDetail/Slaughtered";
import ActionButton from "../Common/ActionButton";
import AuditLogs from "../Common/AuditLogs";
import Attachment from "../Common/Attachment";
import IncidentMainDetail from "../Common/IncidentMainDetail";
import IncidentStatusUpdate from "../Common/IncidentStatusUpdate";
import TotalAnimals from "./InfectionDetail/TotalAnimals";

const AnimalHealthIncident = (props) => {
  let { id, followup, shareDiseaseId } = useParams();
  id = parseInt(encrypt.decrypt(id));
  followup = parseInt(encrypt.decrypt(followup));
  shareDiseaseId = shareDiseaseId ? parseInt(encrypt.decrypt(shareDiseaseId)) : 0;
  //console.log(id, followup, shareDiseaseId);
  const [nonGovUserUI, setNonGovUserUI] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = auth.getUserPermission();
  const followupText = followup > 0 ? " (" + props.t("FollowupReport") + ")" : "";
  const mainformRef = React.useRef(null);
  const transaction = useSelector((state) => state.transaction);

  const handleChangeDTO = (dto, state) => {
    dispatch(transAction.setTransactionDto(dto, state));
  };
  const handleChange = (name, value, state) => {
    dispatch(transAction.setTransactionbyName(name, value, state));
  };

  const getIncident = useCallback(async () => {
    dispatch(transAction.newTransaction());
    setNonGovUserUI(validation.isUserNotLoggedIn());
    if (id > 0) {
      getIncidentTransaction(id);
    } else {
      props.setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getIncident();
  }, [id, dispatch]);

  const getIncidentTransaction = async (id) => {
    props.setLoading(true);
    const response = await animalHealthAction.getIncidentsTransactionById(id);
    if (response.data) {
      const res = response.data.ResponseData;
      console.log("holding---", res.IncidentHoldings);
      handleChangeDTO(res.IncidentHoldings, iAnimalHealth.AH_Constants.IH);
      handleChangeDTO(res.IncidentInfectionDetail, iAnimalHealth.AH_Constants.IID);
      handleChangeDTO(res.IncidentsTransaction.Users.UsersProfile, iIncident.SharedDTO.UP);
      handleChangeDTO(
        res.AnimalHealthInvestigation == null ? new iAnimalHealth.AnimalHealthInvestigationDTO() : res.AnimalHealthInvestigation,
        iAnimalHealth.AH_Constants.AHI
      );

      handleChange("InfectionStartDate", new Date(res.InfectionStartDate.split("T")[0]), iAnimalHealth.AH_Constants.AHT);
      res.InfectionConfirmDate && handleChange("InfectionConfirmDate", new Date(res.InfectionConfirmDate.split("T")[0]), iAnimalHealth.AH_Constants.AHT);
      handleChange("InfectionTypeCode", res.InfectionTypeCode, iAnimalHealth.AH_Constants.AHT);

      handleChange("Circulate", res.Circulate, iAnimalHealth.AH_Constants.AHT);
      await iIncident.getIncidentTransactionData(res, followup, handleChangeDTO, handleChange);
      props.setLoading(false);
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    // var t = mainformRef.current.dispatchEvent(
    //   new Event("submit", { cancelable: true, bubbles: true })
    // );
    if (!formValidation()) {
      return false;
    }
    props.setLoading(true);
    const data = new iAnimalHealth.AnimalHealthTransactionDTO();
    const trans = transaction.AnimalHealthTransactionDTO;
    data.IncidentsTransaction = iIncident.setIncidentTransaction(transaction, enums.IncidentTypeEnum.AnimalHealth, followup, shareDiseaseId);
    data.IncidentHoldings = transaction.IncidentsHoldingsDTO;
    data.IncidentInfectionDetail = transaction.IncidentInfectionDetailsDTO;
    data.InfectionStartDate = trans.InfectionStartDate;
    data.InfectionConfirmDate = trans.InfectionConfirmDate;
    data.Circulate = trans.Circulate;
    data.InfectionTypeCode = trans.InfectionTypeCode;
    data.Area = trans.Area;
    data.AnimalHealthInvestigation = null;
    if (user && (validation.isMinistryUser() || validation.isGovtUser()) && !isRejectAction() && !isCommentOnlyAction()) {
      data.AnimalHealthInvestigation = transaction.AnimalHealthInvestigationDTO;
      data.AnimalHealthInvestigation.AnimalLabAnalysis = transaction.AnimalLabAnalysisDTO;
      data.AnimalHealthInvestigation.ImmunizedAnimalDetails = transaction.AnimalImmunizedDetailsDTO;
      data.AnimalHealthInvestigation.AnimalControlProceduers = transaction.AnimalControlProceduersDTO;
      data.AnimalHealthInvestigation.AnimalDead = transaction.AnimalDeadDTO;
      data.AnimalHealthInvestigation.AnimalDiscarded = transaction.AnimalDiscardedDTO;
      data.AnimalHealthInvestigation.AnimalNewlyAdded = transaction.AnimalNewlyAddedDTO;
      data.AnimalHealthInvestigation.AnimalSlaughtered = transaction.AnimalSlaughteredDTO;
    }
    data.setHoldingType(transaction.IncidentsHoldingsDTO.HoldingTypeId);
    console.log("data from sever: ", data);

    await sendRequest(data);
  };
  const sendRequest = async (data) => {
    let res = null;
    if (!transaction.isUpdate && followup === 0 && shareDiseaseId === 0) {
      res = await animalHealthAction.createAnimalHealth(data);
    } else if (followup > 0) {
      res = await animalHealthAction.createFollowUpAnimalHealth(data);
    } else if (shareDiseaseId > 0) {
      res = await animalHealthAction.createFollowUpSharedDisease(data);
    } else if (transaction.isUpdate) {
      res = await animalHealthAction.updateAnimalHealth(data);
    }
    if (res.data && res.data.IsSuccess) {
      const respData = res.data.ResponseData;
      if (!transaction.isUpdate) {
        swal(props.t("success"), props.t("submitSuccessFully") + props.t("yourRefNoIs") + respData.IncidentsTransaction.RefNo, "success");
      } else {
        swal(props.t("success"), props.t("submitSuccessFully"), "success");
      }
      props.setLoading(false);
      navigate(`/manageincident/${enums.SearchCriteriaEnum.MyIncidents}`);
    }
  };
  const validateDetailForm_public = (e) => {
    const inputFeilds_main = validation.validateForm("mainDetailForm");

    if (!inputFeilds_main || transaction.IncidentInfectionDetailsDTO === 0) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    if (transaction.FileAttachmentDTO.length === 0) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }
    if (!validation.captchaValidation(transaction, props)) {
      return false;
    }
    return true;
  };

  const validateDetailForm_user = (e) => {
    const actionFrom = validation.validateForm("actionbuttonForm", "CirculateToEmirates");
    const inputFields_main = validation.validateForm("mainDetailForm", "immunizedAnimalsForms");

    if (isDeleteAction()) return true;

    if (isRejectAction() || isCommentOnlyAction()) {
      if (actionFrom) return true;
      else {
        toast(props.t("fillAllRequiredField"), { type: "warning" });
        console.log("Actionbutton Form is not filled.");
        return false;
      }
    }
    if (!validation.commonValidation(transaction, props)) {
      return false;
    }
    if (validation.isGovtUser() && transaction.AnimalControlProceduersDTO?.some((x) => x.Code === true)) {
      if (transaction.AnimalImmunizedDetailsDTO.length === 0) {
        toast(props.t("immunizedAnimalsDetailsRequired"), { type: "warning" });
        return false;
      }
    }
    if (validation.isGovtUser() && !ValidateInfectionDetail(transaction.IncidentInfectionDetailsDTO)) {
      toast(props.t("missingInfectionDetail"), { type: "warning" });
      return false;
    }
    if (
      !validation.validateInvestigationDetailForm() ||
      !actionFrom ||
      !inputFields_main
      // || transaction.AnimalHealthInvestigationDTO.TestType === enums.TestTypeEnum.Confirmed ||
    ) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    if (transaction.FileAttachmentDTO.length === 0) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }
    return true;
  };
  const formValidation = () => {
    if (auth.checkLogin()) {
      return validateDetailForm_user();
    } else {
      return validateDetailForm_public();
    }
  };
  const isDeleteAction = () => {
    return transaction.IncidentsMainTransactionDTO.WfActionCode === enums.WorkFlowActionCodeEnum.Delete;
  };
  const isRejectAction = () => {
    return (
      transaction.IncidentsMainTransactionDTO.WfActionCode === enums.WorkFlowActionCodeEnum.RejectIt ||
      transaction.IncidentsMainTransactionDTO.WfActionCode === enums.WorkFlowActionCodeEnum.Redirect ||
      transaction.IncidentsMainTransactionDTO.WfActionCode === enums.WorkFlowActionCodeEnum.ReOpen ||
      transaction.IncidentsMainTransactionDTO.WfActionCode === enums.WorkFlowActionCodeEnum.FinallyClosed ||
      transaction.IncidentsMainTransactionDTO.StatusCode === enums.WorkFlowStatusCodeEnum.Rejected
    );
  };
  const isCommentOnlyAction = () => {
    return transaction.IncidentsMainTransactionDTO.WfActionCode === enums.WorkFlowActionCodeEnum.WriteCommentOnly;
  };
  const ValidateInfectionDetail = (data) => {
    const val1 = data.some((x) => x.InfectionDetailGroupType === enums.InfectionDetailGroupEnum.Default);
    const val2 = data.some((x) => x.InfectionDetailGroupType === enums.InfectionDetailGroupEnum.DetailsOfAnimalsSusceptibleToInfection);
    const val3 = data.some((x) => x.InfectionDetailGroupType === enums.InfectionDetailGroupEnum.DetailsOfAnimalsWithSuspectedInfection);
    const val4 = data.some((x) => x.InfectionDetailGroupType === enums.InfectionDetailGroupEnum.InfectedClinicallyAnimalsDetails);
    const val5 = data.some((x) => x.InfectionDetailGroupType === enums.InfectionDetailGroupEnum.DetailsOfTotalAnimalsInPossession);
    return val1 && val2 && val3 && val4 && val5;
  };
  return (
    <div>
      <div className="tab-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="container page-title p-3 pl-4 mb-3">
              <h3>
                {props.t("reportAnimalHealthIncident")} {followupText}
              </h3>
            </div>
            <div className="col-sm-12">
              <Tabs defaultActiveKey="mainDetail">
                <Tab eventKey="mainDetail" title={props.t("mainDetail")}>
                  <div className="tab-item-wrapper">
                    {transaction.isUpdate && <IncidentStatusUpdate {...props} />}
                    <form className="mainDetailForm" ref={mainformRef}>
                      <IncidentMainDetail handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                    </form>
                  </div>
                </Tab>

                <Tab eventKey="infectionDetail" title={props.t("infectionDetail")}>
                  <div className="tab-item-wrapper">
                    {nonGovUserUI && (
                      <>
                        <div className="container">
                          <InfectionDefault isUpdate={transaction.isUpdate} handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />
                        </div>
                        <Attachment
                          required={true}
                          handleChange={handleChange}
                          handleChangeDTO={handleChangeDTO}
                          code={enums.AttachmentEnum.Other}
                          canUploadFile={validation.isGovtUser()}
                          {...props}
                        />
                      </>
                    )}
                    {!nonGovUserUI && (
                      <div className="infectionsForm">
                        <InfectionDefault isUpdate={transaction.isUpdate} handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />
                        <TotalAnimals isUpdate={transaction.isUpdate} handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />
                        <InfectionCAD isUpdate={transaction.isUpdate} handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />
                        <InfectionASusceptible isUpdate={transaction.isUpdate} handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />
                        <InfectionASuspected isUpdate={transaction.isUpdate} handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />
                        <Dead isUpdate={transaction.isUpdate} handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />
                        <Discarded isUpdate={transaction.isUpdate} handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />
                        <Slaughtered isUpdate={transaction.isUpdate} handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />
                      </div>
                    )}
                  </div>
                </Tab>

                {validation.isUserLoggedIn() && (
                  <Tab eventKey="InvestigationDetails" title={props.t("investigationDetail")}>
                    <div className="tab-item-wrapper">
                      <form id="investigationDetailForm">
                        <InvestigationDetail handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />
                      </form>
                    </div>
                  </Tab>
                )}
              </Tabs>
              <div className="tab-content">
                {!nonGovUserUI && (
                  <Attachment
                    required={true}
                    handleChange={handleChange}
                    handleChangeDTO={handleChangeDTO}
                    code={enums.AttachmentEnum.Other}
                    canUploadFile={validation.isGovtUser()}
                    {...props}
                  />
                )}
                {!nonGovUserUI && <AuditLogs isUpdate={transaction.isUpdate} {...props} />}

                <ActionButton handleChange={handleChange} handleChangeDTO={handleChangeDTO} handleSubmitForm={handleSubmitForm} {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimalHealthIncident;
