import React from "react";
import { useSelector } from "react-redux";
import { iFoodSafetyBG } from "../../../Imports";
import * as Control from "../SharedControl";

const RiskInfo = (props) => {
  const transaction = useSelector((state) => state.transaction);
  const FSBGRI = iFoodSafetyBG.FSBD_Constants.FSBGRI;

  const changeControl = (e) => {
    props.handleChange(e.target.name, e.target.value, FSBGRI);
  };
  const handleChange = (name, value) => {
    props.handleChange(name, value, FSBGRI);
  };
  return (
    <div className="container my-2">
      <div className="row container-bg">
        <Control.RiskSource transaction={transaction[FSBGRI]} changeControl={changeControl} />
        <Control.RiskSourceGroup transaction={transaction[FSBGRI]} changeControl={changeControl} />
        <Control.HazardMain transaction={transaction[FSBGRI]} changeControl={changeControl} />
        <Control.HazardSub transaction={transaction[FSBGRI]} changeControl={changeControl} />
        <Control.HazardDescription transaction={transaction[FSBGRI]} changeControl={changeControl} />
        <Control.LabName transaction={transaction[FSBGRI]} changeControl={changeControl} />
        <Control.LabAddress transaction={transaction[FSBGRI]} changeControl={changeControl} />
        <Control.LabTestType transaction={transaction[FSBGRI]} changeControl={changeControl} />
        <Control.LabTestDate transaction={transaction[FSBGRI]} changeControl={handleChange} isRequired={false} />
        <Control.SampleCount transaction={transaction[FSBGRI]} changeControl={handleChange} />
        <Control.SampleLocation transaction={transaction[FSBGRI]} changeControl={handleChange} />
        <Control.LabTestResult transaction={transaction[FSBGRI]} changeControl={handleChange} />
        <Control.LegalReference transaction={transaction[FSBGRI]} changeControl={handleChange} />
        <Control.ExtraLegalReference transaction={transaction[FSBGRI]} changeControl={handleChange} />
      </div>
    </div>
  );
};

export default RiskInfo;
