import React from "react";
import { useSelector } from "react-redux";
import { util, auth, iAnimalHealth, iIncident, enums, validation } from "../../../Imports";
import { DeleteButton } from "../../Common/IncidentCommonImport";

const InfectionCard = (props) => {
  const user = auth.getUserPermission();
  const transaction = useSelector((state) => state.transaction);
  const deleteItem = (e) => {
    // console.log(e);
    var filterData = transaction[iAnimalHealth.AH_Constants.IID].filter((x) => x.Uid !== e.Uid);
    props.handleChangeDTO(filterData, iAnimalHealth.AH_Constants.IID);
    if (props.type === enums.InfectionDetailGroupEnum.Default) {
      props.UpdateSharedDiseaseValue(filterData);
    }
  };
  const updateDetail = (e) => {
    props.updateDisease(e);
  };
  const convertToCommaSepString = (e) => {
    return e
      .map((sym) => {
        return props.currentLanguage === "ar" ? sym.NameAr : sym.NameEn;
      })
      .join(", ");
  };

  return (
    <div>
      {transaction[iAnimalHealth.AH_Constants.IID] &&
        transaction[iAnimalHealth.AH_Constants.IID]
          .filter((x) => x.InfectionDetailGroupType === props.type)
          .map((e, i) => (
            <div className="card my-2" key={e.Uid ? e.Uid + i : e.Id}>
              <div className="card-body row">
                {util.stringEmpty(e.AnimalTypeName) && (
                  <div className="col-md-2">
                    <small className="golden">{props.t("animalType")}</small> :<small> {e.AnimalTypeName}</small>
                  </div>
                )}
                {util.stringEmpty(e.InfectionDiseaseDetails) && (
                  <div className="col-md-3">
                    <small className="golden">{props.t("diseaseName")}</small> :<small>{convertToCommaSepString(e.InfectionDiseaseDetails)}</small>
                  </div>
                )}
                {util.stringEmpty(e.InfectionSymptomDetails) && (
                  <div className="col-md-3">
                    <small className="golden">{props.t("clinicalSymptoms")}</small>:<small>{convertToCommaSepString(e.InfectionSymptomDetails)}</small>
                  </div>
                )}

                {util.stringEmpty(e.NoOfInfectedAnimals) && (
                  <div className="col-md-2">
                    <small className="golden">{props.t("numberOfInfectedAnimalsInPossession")}</small>:<small> {e.NoOfInfectedAnimals}</small>
                  </div>
                )}

                {util.stringEmpty(e.NoOfAnimals) && (
                  <div className="col-md-2">
                    <small className="golden">{props.t("numberofAnimals")}</small>:<small> {e.NoOfAnimals}</small>
                  </div>
                )}
                {util.stringEmpty(e.NoOfPossibleInfectedAnimals) && (
                  <div className="col-md-3">
                    <small className="golden">{props.t("numberOfPossibleInfectedAnimals")}</small>:<small> {e.NoOfPossibleInfectedAnimals}</small>
                  </div>
                )}
                {util.stringEmpty(e.NoOfDeadAnimals) && (
                  <div className="col-md-2">
                    <small className="golden">{props.t("numberOfDeadAnimals")}</small>:<small> {e.NoOfDeadAnimals}</small>
                  </div>
                )}
                {util.stringEmpty(e.AnimalBreed) && (
                  <div className="col-md-2">
                    <small className="golden">{props.t("animalBreed")}</small>:<small> {e.AnimalBreed}</small>
                  </div>
                )}
                {util.stringEmpty(e.AnimalGender) && (
                  <div className="col-md-2">
                    <small className="golden">{props.t("animalGender")}</small>:<small> {props.t(e.AnimalGender)}</small>
                  </div>
                )}
                {util.stringEmpty(e.AnimalAge) && (
                  <div className="col-md-2">
                    <small className="golden">{props.t("AnimalAge")}</small>:<small> {e.AnimalAge}</small>
                  </div>
                )}
                {util.stringEmpty(e.Anatomy) && (
                  <div className="col-md-3">
                    <small className="golden">{props.t("Anatomy")}</small>:<small> {e.Anatomy}</small>
                  </div>
                )}
                {util.stringEmpty(e.OtherDisease) && (
                  <div className="col-md-3">
                    <small className="golden">{props.t("otherDisease")}</small>:<small> {e.OtherDisease}</small>
                  </div>
                )}
                {util.stringEmpty(e.OtherSymptoms) && (
                  <div className="col-md-2">
                    <small className="golden">{props.t("otherSymptoms")}</small>:<small> {e.OtherSymptoms}</small>
                  </div>
                )}
              </div>
              {(props.type !== enums.InfectionDetailGroupEnum.Default ||
                transaction[iIncident.SharedDTO.IT].FollowUp === 1 ||
                validation.isUserNotLoggedIn() ||
                validation.isUserLoggedIn()) && (
                <DeleteButton
                  {...props}
                  deleteHandler={() => {
                    deleteItem(e);
                  }}
                />
              )}
              {props.type === enums.InfectionDetailGroupEnum.Default &&
                user &&
                user.UnitType === enums.UnitTypeEnum.GovernmentEntity &&
                e.InfectionDiseaseDetails.length === 0 && (
                  <div className="editBtn">
                    <i
                      style={{ cursor: "pointer", color: "#229920" }}
                      className="fa fa-edit"
                      onClick={() => {
                        updateDetail(e);
                      }}
                    ></i>
                  </div>
                )}
            </div>
          ))}
    </div>
  );
};

export default InfectionCard;
